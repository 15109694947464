let rol = localStorage.getItem('rol');
let Menu = [];

switch(rol){
    case 'rol-admin':
        Menu = [
            {
                name: 'Inicio',
                path: '/inicio',
                icon: 'img/icons/connection-bars.svg',
                label: { value: 1, classNme: 'bg-success' }
            },
            {
                name: 'Mantenedor usuarios',
                path: '/usuarios',
                icon: 'img/icons/user-check.svg',
                label: { value: 1, classNme: 'bg-success' }
            },
        ];
        break;
    case 'rol-lector':
        Menu = [
            {
                name: 'Inicio',
                path: '/inicio',
                icon: 'img/icons/connection-bars.svg',
                label: { value: 1, classNme: 'bg-success' }
            },
        ];
        break;
    default:
        Menu = []
        break;
}

export default Menu;