export const getCookieByName = (name = "token") => {
  const cookies = document.cookie.split(';')

  let cookie = '';
  cookies.forEach(item => {
    const [ key, value ] = item.split('=');
    if(key.replace(' ', '') === name){
      cookie = value;
    }
  });

  return cookie;
}