import React, { Suspense, lazy, useEffect } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
/* loader component for Suspense */
import PageLoader from './components/Common/PageLoader';

/* eslint-disable-next-line */
import Core from './components/Core/Core';
/* eslint-disable-next-line */
import Bootstrap from './components/Bootstrap/Bootstrap';
/* eslint-disable-next-line */
import Common from './components/Common/Common';
/* eslint-disable-next-line */
import Colors from './components/Colors/Colors';
/* eslint-disable-next-line */
import FloatButton from './components/FloatButton/FloatButton';
/* eslint-disable-next-line */
import Utils from './components/Utils/Utils';

import { checkLocalToken } from './services/UserSSOService';
import { getCookieByName } from './helpers/getCookieByName';
import { UnauthorizedScreen } from './views/NoLoginCP/UnauthorizedScreen';
import { LoginRequireScreen } from './views/NoLoginCP/LoginRequireScreen';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props}/>;

const InicioScreen = lazy(() => import('./views/Inicio/InicioScreen'));
const TestAuth = lazy(() => import('./views/Inicio/TestAuth'));
const UsersScreen = lazy(() => import('./views/Pages/Users/UsersScreen'));
const PageNotFound = lazy(() => import('./views/Errors/PageNotFound'));
const UsersMainScreen = lazy(() => import('./views/UserSSO/UsersMainScreen'));

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };
    const animationName = 'rag-fadeIn'

    const localToken = getCookieByName() || '';
    const logged = localStorage.getItem('log-ged') || '';
    const rolUser = localStorage.getItem('rol') || '';

    useEffect(() => {
        if(location.pathname !== "/unauthorized"){
            checkLocalToken()
        }
    }, [ location.pathname ])

    if(logged){
        return (
            <Suspense fallback={<PageLoader/>}>
                <Switch>
                    <Route exact path="/unauthorized" component={UnauthorizedScreen} />
                    <Redirect to="/unauthorized" />
                </Switch>
            </Suspense>
        )
    }

    if(!localToken || !rolUser){
        return (
            <Suspense fallback={<PageLoader/>}>
                <Switch>
                    <Route exact path="/" component={LoginRequireScreen} />
                    <Route exact path="/unauthorized" component={UnauthorizedScreen} />
                    <Redirect to="/"/>
                </Switch>
            </Suspense>
        )
    }


    if(rolUser === 'rol-admin'){
        return (
            <Core>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader/>}>
                                <Switch location={location}>
                                    <Route path="/inicio" component={waitFor(InicioScreen)}/>
                                    <Route path="/mantenedor-usuarios" component={waitFor(UsersScreen)}/>
                                    <Route path="/usuarios" component={waitFor(UsersMainScreen)}/>
                                    <Route path="/PageNotFound" component={waitFor(PageNotFound)}/>
                                    <Route path="/test-auth" component={waitFor(TestAuth)}/>
                                    <Redirect to="/inicio"/>
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Core>
        )
    } else if (rolUser === 'rol-lector'){
        return (
            <Core>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader/>}>
                                <Switch location={location}>
                                    <Route path="/inicio" component={waitFor(InicioScreen)}/>
                                    <Route path="/PageNotFound" component={waitFor(PageNotFound)}/>
                                    <Redirect to="/inicio"/>
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Core>
        )
    } else {
        return (
            <Core>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader/>}>
                                <Switch location={location}>
                                    <Route path="/PageNotFound" component={waitFor(PageNotFound)}/>
                                    <Redirect to="/PageNotFound"/>
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Core>
        )
    }
}

export default withRouter(Routes);
