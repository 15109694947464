const site = window.location.origin;
const productionMode = process.env.NODE_ENV === 'production';

export const webSSO = 'https://sso.cadem.cl';
export const API_SSO = 'https://backend-sso-xbstdiqb2a-ue.a.run.app';

export const completeSsoRedirect = `${webSSO}/login?site=${utf8_to_b64(site)}`;
export const completeSsoLogout = `${webSSO}/logout?site=${utf8_to_b64(site)}`;

export const SIZE_FILE =  30000000;
export const URL_UPLOAD_PREMIOS = 'https://api-upload-premios-cuali-xbstdiqb2a-ue.a.run.app/uploadfile/';
export const API_BASE_URL = productionMode
    ? 'https://backend-premios-cuali-xbstdiqb2a-ue.a.run.app' // Produccion Cloud Run
    : 'http://localhost:5000'; // Desarrollo

function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent( str )));
}