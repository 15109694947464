import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import pubsub from 'pubsub-js';

import './Header.scss';
import './HeaderMenuLinks.scss';
import { completeSsoLogout } from "../../configuration";

class Header extends Component {

    state = {
        pageTitle: '',
        navIcon: 'menu-link-slide',
        dropdownOpen: false
    }

    componentWillMount() {
        this.pubsub_token = pubsub.subscribe('setPageTitle', (ev, title) => {
            this.setState({pageTitle: title});
        });
        this.pubsub_token_icon = pubsub.subscribe('setNavIcon', (ev, icon) => {
            this.setState({navIcon: icon});
        });
    }

    componentWillUnmount() {
        pubsub.unsubscribe(this.pubsub_token);
        pubsub.unsubscribe(this.pubsub_token_icon);
    }

    toggle = () => {
        this.setState(prevState => ({
          dropdownOpen: !prevState.dropdownOpen
        }));
    }

    logout = () =>{
        localStorage.clear();
        window.location.href = completeSsoLogout;
    }


    render() {
        return (
            <header className="header-container">
                <nav>
                    {/* se cambio la clase d-lg-none a d-lg-block*/}
                    <ul className="d-lg-block">
                        <li>
                            <a id="show-sidebar" className={"menu-link "+this.state.navIcon}>
                                <span><em></em></span>
                            </a>
                        </li>
                    </ul>
                    
                    <h2 className="header-title">{this.state.pageTitle}</h2>

                    <ul className="float-right">
                        <li>
                            <button
                                style={{ height: '65px', cursor: 'pointer' }}
                                onClick={this.logout.bind(this)}
                                className="btn btn-link text-decoration-none w-100 text-white"
                                title="Salir"
                            >
                                <span><em className="ion-log-out icon-fw"></em></span>
                                <span style={{ fontSize: '16px', verticalAlign: '-10%', lineHeight: '1' }}>Salir</span>
                            </button>
                        </li>
                    </ul>

                </nav>
            </header>
        );
    }
}

export default Header;
